@tailwind base;
@tailwind components;
@tailwind utilities;


.content{

  padding: 0% 10%;
  width: 100%;


}
.content table{

display: block;


}
.content p{

  padding: 1.5em 0em 0em 0em;


}
table td{

  width: 1000px;


}
.content tr{

padding: 10px;

}
.content td{

  padding: 1.5em;
    border: 1px solid #66941E;

}
.content table{

   font-weight: 200;
   color: #5a5a5a;

}

